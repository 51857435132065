import { ReactComponent as Uav } from "assets/icons/uav.svg";
import { ReactComponent as Satellites } from "assets/icons/satellites.svg";
import { ReactComponent as Station } from "assets/icons/radar.svg";

import styles from "./industries.module.scss";

export const useCaseItems = [
  {
    icon: <Satellites className={styles.icon} />,
    title: "SATCOM & SDR",
  },
  {
    icon: <Uav className={styles.icon} />,
    title: "Robotyka",
  },
  {
    icon: <Station className={styles.stationIcon} />,
    title: "Platformy Danych i AI",
  },
];

export const technologyItems = [
  { title: "SAFE", text: "Zero-trust security reducing costly threats" },
  { title: "DLT", text: "Efficient from edge to edge" },
  { title: "AUTO", text: "Distributed system working 24/7" },
];

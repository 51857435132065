import React from "react";

import { Title } from "components";

import logo from "assets/images/logo1.png";

import styles from "./contact.module.scss";

export const Contact: React.FC = () => {
  return (
    <footer className={styles.container}>
      <Title containerClassName={styles.titleContainer}>Kontakt</Title>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div>
            <h6 className={styles.subtitle}>Leads Sp. z o.o.</h6>
          </div>
          <div>
            <br />
            <a href="tel: +48669328447" className={styles.link}>
              +48 669 328 447
            </a>
            <br />
            <a href="mailto: albert.lewandowski@leadsu.pl" className={styles.link}>
              albert.lewandowski@leadsu.pl
            </a>
            <br />
            <a>
              NIP 6783191722 | KRS 0000911721
            </a>
            <br />
            <a>
              Osiedle Przy Arce 5 / 228, 31-845 Kraków
            </a>
            <br />
          </div>
        </div>
        <img src={logo} alt="Magellanic logo" className={styles.logo} />
      </div>
    </footer>
  );
};

import React from "react";
import classNames from "classnames";

import styles from "./hamburger.module.scss";

interface Props {
  isOpen: boolean;
  toggleMenu: () => void;
  darkPrimary?: boolean;
}

export const Hamburger: React.FC<Props> = ({
  isOpen,
  toggleMenu,
  darkPrimary,
}) => {
  return (
    <button type="button" className={styles.outer} onClick={toggleMenu}>
      <div className={isOpen ? styles.innerOpen : styles.innerClose} />
      <div
        className={classNames({
          [styles.innerOpen]: isOpen && !darkPrimary,
          [styles.innerClose]: !isOpen && !darkPrimary,
          [styles.innerDarkOpen]: isOpen && darkPrimary,
          [styles.innerDarkClose]: !isOpen && darkPrimary,
        })}
      />
    </button>
  );
};

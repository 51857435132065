import React from "react";
import classNames from "classnames";

import { TitleProps } from "./title.types";

import { ReactComponent as Detail } from "assets/icons/title-detail.svg";

import styles from "./title.module.scss";

export const Title: React.FC<TitleProps> = ({ children, containerClassName, className, light }) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      <Detail className={classNames(styles.detail, { [styles.lightDetail]: light })} />
      <h2 className={classNames(styles.title, className, { [styles.lightTitle]: light })}>{children}</h2>
    </div>
  );
};

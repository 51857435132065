import React from "react";

import { Header } from "components";

import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styles from "./banner.module.scss";

interface Props {
  navigateTo?: string;
  shortView?: boolean;
}

export const Banner: React.FC<Props> = ({ navigateTo, shortView }) => {
  const navigateToSection = () => {
    window.location.href = navigateTo || "#what-we-do";
  };

  return (
    <div className={shortView ? styles.shortContainer : styles.container}>
      <div className={styles.content}>
        <Header />
        <div className={styles.innerWrapper}>
          <section
            className={
              shortView ? styles.shortInnerContent : styles.innerContent
            }
          >
            <h2 className={shortView ? styles.shortTitle : styles.title}>
              Leads <br />
              <span>Rozwiązania SaaS, Cloud i Cybersecurity</span>
            </h2>

            {!shortView && (
              <>
                <p className={styles.description}>
                  Tworzymy i wdrażamy najnowocześniejsze rozwiązania dla branż
                  infrastruktury krytycznej, oraz wspieramy rozwój ekosystemu
                  startupów współpracując z programami akceleracyjnymi.
                </p>

                <a href="#contact">
                  <button
                    type="button"
                    className={styles.button}
                    onClick={navigateToSection}
                  >
                    Skontaktuj się z nami
                    <Arrow />
                  </button>
                </a>
              </>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export const valueProposition = [
  {
    title: "Wartość biznesowa",
    text: "Przy wszystkich projektach kierujemy się jakością dostarczaną dla biznesu.",
  },
  {
    title: "Jakość",
    text: "Kluczowa jest jakość oprogramowania, które wychodzi spod naszych dłoni.",
  },
  {
    title: "Nowoczesne rozwiązania",
    text: "Dzięki współpracy ze startupami, mamy dostęp do szerokiego zaplecza najnowszych technologii ze świata.",
  },
  {
    title: "Konsulting",
    text: "Wykorzystujemy naszą wiedze i doświadczenie, aby wspierać firmy w ich transformacji cyfrowej.",
  },
];

import React from "react";

import { Banner } from "./banner/banner";
import { Industries } from "./industries/industries";
import { Mission } from "./mission/mission";
import { Values } from "./values/values";
import { Contact, Newsletter, Photo } from "components";

import photo from "assets/images/rocket-launch.jpg";

// import styles from "./landing.module.scss";

export const LandingPage: React.FC = () => {
  return (
    <div>
      <Banner />
      <Mission />
      <Values />
      <Photo image={photo} />
      <Industries />
      <Newsletter />
      <Contact />
    </div>
  );
};

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Hamburger, MobileMenu } from "components";
import { menuItems } from "../mobile-menu/mobile-menu.constants";
import { LANDING_PAGE } from "constants/routes.constants";

import logo from "assets/images/logo-secondary.png";
import logoDark from "assets/images/logo-small.png";

import styles from "./header.module.scss";
import classNames from "classnames";

interface Props {
  darkBackground?: boolean;
  darkPrimary?: boolean;
}

export const Header: React.FC<Props> = ({ darkBackground, darkPrimary }) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => setMenuOpen((wasOpen) => !wasOpen);
  const handleMenuOpen = (isOpen: boolean) => () => setMenuOpen(isOpen);

  return (
    <>
      <header className={styles.container}>
        <Link to={LANDING_PAGE.path}>
          <img
            src={darkPrimary ? logoDark : logo}
            alt="Magellanic logo"
            className={styles.logo}
          />
        </Link>
        <div className={styles.itemsContainer}>
          <div className={styles.items}>
            {menuItems.map(({ name, path }) => (
              <a
                key={name}
                href={path}
                className={classNames({
                  [styles.darkMenuItem]: darkBackground,
                  [styles.menuItem]: !darkPrimary,
                  [styles.menuItemDark]: darkPrimary,
                })}
              >
                {name}
              </a>
            ))}
          </div>
          <Hamburger
            isOpen={isMenuOpen}
            toggleMenu={toggleMenu}
            darkPrimary={darkPrimary}
          />
        </div>
      </header>
      <MobileMenu isOpen={isMenuOpen} closeMenu={handleMenuOpen(false)} />
    </>
  );
};

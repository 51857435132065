import React from "react";
import classNames from "classnames";

import styles from "./photo.module.scss";

interface Props {
  image: string;
  className?: string;
}

export const Photo: React.FC<Props> = ({ image, className }) => {
  return (
    <img
      src={image}
      className={classNames(styles.photo, className)}
      alt="Space"
    />
  );
};

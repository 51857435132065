import React from "react";

import { Banner } from "./banner/banner";
import { WhatWeDo } from "./what-we-do/what-we-do";
import { Industries } from "pages/offer/industries/industries";
import { Contact, Newsletter } from "components";

export const OfferPage: React.FC = () => {
  return (
    <div>
      <Banner />
      <WhatWeDo />
      <Industries />
      <Newsletter />
      <Contact />
    </div>
  );
};

import React from "react";

import { Title } from "components";
import { useCaseItems } from "./industries.constants";

import styles from "./industries.module.scss";

export const Industries: React.FC = () => {
  return (
    <>
      <section className={styles.container} id="industry">
        <div>
          <Title>Sektory</Title>
        </div>
        <div className={styles.content}>
          {useCaseItems.map((item) => (
            <div key={item.title} className={styles.item}>
              {item.icon}
              <h4 className={styles.itemTitle}>{item.title}</h4>
            </div>
          ))}
        </div>
      </section>
      {/* <section className={styles.iconsContainer}> */}
      {/*  {technologyItems.map(({ title, text }) => ( */}
      {/*    <div key={title} className={styles.iconItem}> */}
      {/*      <h2 className={styles.technologyItem}>{title}</h2> */}
      {/*      <p className={styles.iconText}>{text}</p> */}
      {/*    </div> */}
      {/*  ))} */}
      {/* </section> */}
    </>
  );
};

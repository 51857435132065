import React from "react";

import { Title, Accordion } from "components";
import { valueProposition } from "./values.constants";

import satellite from "assets/images/satellite.png";

import styles from "./values.module.scss";

export const Values: React.FC = () => {
  return (
    <section className={styles.container} id="values">
      <div>
        <Title containerClassName={styles.titleContainer} className={styles.title} light>
          Nasze Wartości
        </Title>

        {valueProposition.map(({ title, text }) => (
          <Accordion key={title} title={title} text={text} initiallyOpen darkBackground />
        ))}
      </div>
      <div className={styles.imageWrapper}>
        <img src={satellite} alt="Satellite" className={styles.satellite} />
      </div>
    </section>
  );
};

import React from "react";

import { Header } from "components";

import red from "assets/images/re_d.png";
import fengParp from "assets/images/feng-parp.png";
import fengParpMobile from "assets/images/feng-parp-mobile.png";
import { ReactComponent as ParpLogo } from "assets/icons/parp.svg";

import styles from "./re_d.module.scss";
import {Banner} from "pages/landing/banner/banner";

export const RedPage: React.FC = () => {
  return (
    <div>
      {/*<Header darkPrimary />*/}
      <Banner shortView />

      <section className={styles.content}>
        <img src={red} alt="Re_d logo" className={styles.redLogo} />
        <img
          src={fengParpMobile}
          alt="EU signs"
          className={styles.fengParpMobile}
        />
        <img src={fengParp} alt="EU signs" className={styles.fengParpDesktop} />
        <ParpLogo className={styles.parpLogo} />
        <p className={styles.text}>
          Projekt re_d: rethink digital - Central Poland Digitalisation HUB jest
          współfinansowany przez Komisję Europejską w ramach Programu „Cyfrowa
          Europa” na lata 2021-2027 oraz współfinansowany ze środków Unii
          Europejskiej w ramach Programu Fundusze Europejskie dla Nowoczesnej
          Gospodarki 2021-2027, Priorytet II, Środowisko sprzyjające innowacjom,
          działanie 2.22 „Współfinansowanie działań EDIH”.
        </p>

        <a href="https://re-d.pl/" target="_blank" rel="noopener noreferrer">
          <button type="button" className={styles.mainButton}>
            Dowiedz się więcej o ofercie
          </button>
        </a>
      </section>
    </div>
  );
};

import { LandingPage, AboutPage, OfferPage, RedPage } from "pages";
import { RouteConfig } from "types";
import {
  ABOUT_PAGE,
  LANDING_PAGE,
  OFFER_PAGE,
  RED_PAGE,
} from "constants/routes.constants";

export const routes: RouteConfig[] = [
  { ...LANDING_PAGE, component: LandingPage },
  { ...ABOUT_PAGE, component: AboutPage },
  { ...OFFER_PAGE, component: OfferPage },
  { ...RED_PAGE, component: RedPage },
];

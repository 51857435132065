import React from "react";

import { Title, EnterTitle } from "components";

import logo from "assets/images/logo1.png";

import styles from "./about-us.module.scss";

export const AboutUs: React.FC = () => {
  return (
    <div className={styles.container} id="mission">
      <div>
        <Title>Kim jesteśmy?</Title>
        <p className={styles.text}>
          Leads Sp. z o.o. to polska spółka technologia zajmująca się tworzeniem
          dedykowanego oprogramowania, obecnie ukierunkowana głównie na obszar
          cyberbezpieczeństwa oraz autonomiczne urządzenia. Firma z powodzeniem
          realizuje projekty od 2021 roku oraz wspiera rozwoj startupów
          działając w formule venture buildingu, konsultując pomysły, wspierając
          ich realizacje i łącząc z klientami i inwestorami.
        </p>

        <Title>Czym się zajmujemy?</Title>
        <p className={styles.text}>
          Firma z powodzeniem realizuje projekty od 2021 roku oraz wspiera
          rozwoj startupów działając w formule venture buildingu, konsultując
          pomysły, wspierając ich realizacje i łącząc z klientami i inwestorami.
          < br/>
          < br/>
          Jesteśmy zespołem innowatorów z doświadczeniem w projektach z chmurą publiczną (Amazon Web Service, Google Cloud, Microsoft Azure),
          cyberbezpieczeństwem, analizą dużych zbiorów danych, sztucznej inteligencji,
          skalowalnych aplikacji webowych i mobilnych. Przy tym współpracujemy z ekspertami
          ze świata UX/UI, marketingu oraz rozwoju produktu, aby każdy projekt otrzymał
          kompleksowe wsparcie.
          < br/>
          < br/>
          Jesteśmy zaangażowani jako Partner Biznesowy w programie akceleracyjnym Poland Prize
          realizowanym przez Łódzką Specjalną Strefę Ekonomiczną i Polską Agencję Rozwoju
          Przedsiębiorczości, gdzie wspieramy rozwój startupów z obszarów deep tech.
        </p>

        <EnterTitle className={styles.enterTitle}>
          Skontaktuj się z nami!
        </EnterTitle>

        <div className={styles.content}>
          <div>
            <img src={logo} alt="Leads" className={styles.logo} />
          </div>
          <div>
            <h2 className={styles.technologyTitle}>Kontakt</h2>

            <a href="tel: +48669328447" className={styles.link}>
              +48 669 328 447
            </a>
            <br />
            <br />
            <a href="mailto: albert.lewandowski@leadsu.pl" className={styles.link}>
              albert.lewandowski@leadsu.pl
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import { MenuItem } from "./mobile-menu.types";
import {ABOUT_PAGE, OFFER_PAGE, RED_PAGE} from "constants/routes.constants";

export const menuItems: MenuItem[] = [
  { name: "Misja", path: "/#mission" },
  { name: "Sektory", path: "/#industry" },
  { name: "O nas", path: ABOUT_PAGE.path },
  { name: "Oferta", path: OFFER_PAGE.path },
  { name: "Re_d", path: RED_PAGE.path },
  { name: "Kontakt", path: "#contact" },
];

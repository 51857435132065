import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Formik, Form } from "formik";

import { Title } from "components";

import styles from "./newsletter.module.scss";

export const Newsletter: React.FC = () => {
  const [checked, setChecked] = useState<boolean>(false);

  const toggleCheckbox = () => setChecked((wasChecked) => !wasChecked);

  const initialValues = {
    EMAIL: "",
  };

  const URL =
    "https://space.us21.list-manage.com/subscribe/post?u=63658ede06c871637bef261f7&id=f626fad7b9&f_id=007fcae1f0";

  return (
    <section className={styles.container} id="contact">
      <Title className={styles.title} light>
        Bądź na bieżąco z nowościami ze świata nowych technologii
      </Title>
      <MailchimpSubscribe
        url={URL}
        render={({ subscribe, status }) => (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              if (values.EMAIL) {
                subscribe(values);
              }
              resetForm();
            }}
          >
            {({ handleChange, handleBlur }) => {
              return (
                <Form className={styles.content}>
                  <p className={styles.text}>
                    Zapisz się do newslettera
                    <br />
                  </p>
                  {status === "success" && <p className={styles.response}>You subscribed the newsletter!</p>}
                  {status === "error" && (
                    <p className={styles.response}>Error occurred while subscribing the newsletter.</p>
                  )}
                  <input
                    type="input"
                    className={styles.input}
                    name="EMAIL"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <button type="button" className={styles.policyWrapper} onClick={toggleCheckbox}>
                    <div className={styles.checkbox}>
                      <input type="checkbox" value="None" id="roundedOne" onClick={toggleCheckbox} checked={checked} />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="roundedOne" />
                    </div>
                    <p className={styles.policyText}>
                      Zgadzam się na przesyłanie mi treści marketingowych w formie elektronicznej na podany powyżej adres mailowy.
                    </p>
                  </button>
                  <button type="submit" className={styles.button} disabled={!checked}>
                    Dołącz
                  </button>
                </Form>
              );
            }}
          </Formik>
        )}
      />
    </section>
  );
};

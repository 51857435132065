import React from "react";

import { Banner } from "./banner/banner";
import { AboutUs } from "./about-us/about-us";
import { Contact, Newsletter } from "components";

export const AboutPage: React.FC = () => {
  return (
    <div>
      <Banner />
      <AboutUs />
      <Newsletter />
      <Contact />
    </div>
  );
};

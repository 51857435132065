import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

import { AccordionProps } from "./accordion.types";

import styles from "./accordion.module.scss";

export const Accordion: React.FC<AccordionProps> = ({ title, text, initiallyOpen, darkBackground }) => {
  const [isOpen] = useState<boolean>(initiallyOpen);

  const toggleAccordion = () => {};

  const openAnimation = useSpring({
    from: { opacity: "0", maxHeight: "80px" },
    to: { opacity: "1", maxHeight: isOpen ? "600px" : "80px" },
    config: { duration: 300 },
  });

  return (
    <animated.div className={styles.container} style={openAnimation} onClick={toggleAccordion}>
      <div className={styles.accordionHeader}>
        <h3 className={darkBackground ? styles.accordionTitleWhite : styles.accordionTitle}>{title}</h3>
      </div>

      <p className={darkBackground ? styles.accordionContentWhite : styles.accordionContent}>{text}</p>
    </animated.div>
  );
};

import React from "react";

import { Header } from "components";

import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import background from "assets/images/telephone.webp";

import styles from "./banner.module.scss";

interface Props {
  navigateTo?: string;
}

export const Banner: React.FC<Props> = ({ navigateTo }) => {
  const navigateToSection = () => {
    window.location.href = navigateTo || "#what-we-do";
  };

  return (
    <div className={styles.container}>
      <img src={background} alt="Gears" className={styles.background} />
      <div className={styles.overlay} />
      <div className={styles.content}>
        <Header darkBackground />

        <div className={styles.innerWrapper}>
          <section className={styles.innerContent}>
            <h2 className={styles.title}>
              <span>Firma rozumięjąca Deep Tech</span>
            </h2>

            <p className={styles.description}>
              Rozumiemy innowacje. Sami je opracowujemy od czystej kartki papieru
              i współpracujemy z firmami z całego świata, aby skutecznie wdrażać
              nowe rozwiązania, które przyniosą wymierne korzyści biznesowe.
              Wiemy doskonale, jak ważny jest odpowiedni wybór technologii dla powodzenia
              projektu.
            </p>

            <a href="#contact">
              <button
                type="button"
                className={styles.button}
                onClick={navigateToSection}
              >
                Skontaktuj się z nami
                <Arrow />
              </button>
            </a>
          </section>
        </div>
      </div>
    </div>
  );
};

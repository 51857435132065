import React from "react";

import { Title, EnterTitle } from "components";

import styles from "./mission.module.scss";

export const Mission: React.FC = () => {
  return (
    <div className={styles.container} id="mission">
      <div>
        <Title>Oto co robimy</Title>
        <p className={styles.text}>
          Leads Sp. z o.o. to polska spółka technologia zajmująca się tworzeniem dedykowanego oprogramowania, obecnie ukierunkowana
          głównie na obszar cyberbezpieczeństwa oraz autonomiczne urządzenia. Firma z powodzeniem realizuje projekty od 2021 roku oraz
          wspiera rozwoj startupów działając w formule venture buildingu, konsultując pomysły, wspierając ich realizacje i łącząc
          z klientami i inwestorami.
        </p>

        <div className={styles.content}>
          <div>
            <h2 className={styles.technologyTitle}>Cyber</h2>
            <p className={styles.text}>
              Rozwijamy i wdrażamy systemy cyberbezpieczeństwa dla najbardziej narażonych na ataki branż w dowolnym środowisku.
            </p>
          </div>
          <div>
            <h2 className={styles.technologyTitle}>Cloud</h2>
            <p className={styles.text}>
              Wspieramy w migracji do chmury i optymalizacji kosztów oraz wdrażaniu nowych rozwiązań w architekturze multi-cloud.
            </p>
          </div>
          <div>
            <h2 className={styles.technologyTitle}>SaaS</h2>
            <p className={styles.text}>
              Wspieramy w tworzeniu i rozwoju Oprogramowania jako Usługi od samego pomysłu.
            </p>
          </div>
        </div>

        <EnterTitle section="#values" className={styles.enterTitle}>
          Nasza misja
        </EnterTitle>
      </div>
    </div>
  );
};

import React from "react";

import { Title, EnterTitle } from "components";

import styles from "./what-we-do.module.scss";

export const WhatWeDo: React.FC = () => {
  return (
    <div className={styles.container} id="mission">
      <div>
        <Title>Oto co robimy</Title>
        <p className={styles.text}>
          Leads Sp. z o.o. to polska spółka technologia zajmująca się tworzeniem dedykowanego oprogramowania, obecnie ukierunkowana
          głównie na obszar cyberbezpieczeństwa oraz autonomiczne urządzenia. Firma z powodzeniem realizuje projekty od 2021 roku oraz
          wspiera rozwoj startupów działając w formule venture buildingu, konsultując pomysły, wspierając ich realizacje i łącząc
          z klientami i inwestorami.
        </p>

        <div className={styles.content}>
          <div>
            <h2 className={styles.technologyTitle}>Cyber</h2>
            <p className={styles.text}>
              Wspieramy firmy we wdrażaniu skutecznych polis bezpieczeństwa w zakresie IAM (Identity and Access Management), audytach dostępowych, automatyzacji przyznawania dostępów (Self-Service Desk) oraz wdrożenia aplikacji do testowania bezpieczeństwa aplikacji.
            </p>
          </div>
          <div>
            <h2 className={styles.technologyTitle}>Cloud</h2>
            <p className={styles.text}>
              Optymalizacja kosztów, migracje systemów z on-premise do chmury. Wspieramy środowiska takie jak: Amazon Web Service, Google Cloud Platform, Microsoft Azure, Oracle CLoud; oraz umożliwiamy wdrożenie infrastruktury hybrydowej.
            </p>
          </div>
          <div>
            <h2 className={styles.technologyTitle}>SaaS</h2>
            <p className={styles.text}>
              Realizacja prototypowych oraz gotowych do skalowania aplikacji webowych i mobilnych z wykorzystaniem technologii chmurowych i low-code. Zapewniamy rozwój aplikacji od zera, wspierając firmy już w zebraniu wymagań biznesowych.
            </p>
          </div>
        </div>

        <EnterTitle section="#values" className={styles.enterTitle}>
          Nasza misja
        </EnterTitle>
      </div>
    </div>
  );
};
